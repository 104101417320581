<template>
  <v-container class="container">
    <v-row class="text-center pa-1 justify-center align-center">
      <v-col cols="12">
        <v-img
          :src="require('../assets/logopocollay.png')"
          contain
          class="mx-auto logo"
          style="width:70%"
        />
      </v-col>
      <v-col cols="12">
        <h2 class="title">Sistema de Mesa de partes virtual</h2>
      </v-col>
      <v-col cols="12">
        <p>
          El horario de recepción de documentos es de 08:00am hasta las 3:30pm
          de Lunes a Viernes, cualquier documento recibido fuera de este
          horario, será registrado al día útil siguiente a su recepción.
        </p>
      </v-col>
      <v-col cols="12">
        <v-img :src="require('../assets/mesa.svg')" contain height="220" />
      </v-col>
      <v-col cols="12">
        <v-btn color="primary" class="ma-2 white--text" @click="onNew">
          REGISTRO
          <v-icon right dark> mdi-pencil </v-icon>
        </v-btn>
        <v-btn color="primary" class="ma-2 white--text" @click="onSearch">
          CONSULTA
          <v-icon right dark> mdi-file-search-outline </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "HelloWorld",

  data: () => ({}),

  methods: {
    onSearch() {
      window.open("http://consultatramite.munidepocollay.gob.pe", "_blank");
    },
    onNew() {
      this.$router.push("/tramite");
    },
  },
};
</script>

<style scoped>
.container {
  width: 50%;
}

.title {
  font-weight: 500;
  text-transform: uppercase;
}

.logo {
  max-width: 500px;
  height: 150px;
  width: 100%;
}

@media (max-width: 700px) {
  .container {
    width: 100%;
  }

  .logo {
    height: 100px;
  }
}
</style>
