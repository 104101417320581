<template>
  <v-container v-if="isLoading">
    <v-progress-circular indeterminate color="primary"></v-progress-circular>
  </v-container>
  <v-container v-else>
    <v-container v-if="isAvailable">
      <v-app-bar app>
        <v-container class="toolbar">
          <h3 class="toolbar__title">Sistema de Mesa de Partes Virtual</h3>
          <div>
            <v-img
              :src="require('../assets/logopocollay.png')"
              contain
              height="50"
              width="400"
            />
          </div>
        </v-container>
      </v-app-bar>
      <div class="tramite">
        <el-form :model="form" :rules="rules" ref="form" style="width:100%">
          <div class="tramite-remitente">Remitente</div>
          <div class="tramite-remiteForm">
            <div class="field">
              <div class="label">Tipo de persona</div>
              <el-form-item prop="tipo">
                <el-select v-model="form.tipo">
                  <el-option
                    v-for="item in tipos"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="tramite-remitente" v-if="form.tipo === 'J'">
              Documento
            </div>
            <div v-if="form.tipo === 'J'">
              <div class="field">
                <span class="label">RUC</span>
                <el-form-item prop="ruc" :error="errors.documento_identidad">
                  <el-input v-model="form.ruc" @input="changeRuc">
                    <el-button
                      slot="append"
                      icon="el-icon-search"
                      @click="searchRuc"
                      style="
                        background:#409eff;
                        color: white;
                      "
                      >Buscar</el-button
                    >
                  </el-input>
                </el-form-item>
              </div>
              <div class="field">
                <span class="label">Razón social</span>
                <el-form-item prop="razon_social">
                  <el-input
                    v-model="form.razon_social"
                    :disabled="companyIsFound"
                  >
                  </el-input>
                </el-form-item>
              </div>
              <div class="field">
                <span class="label">Correo electrónico</span>
                <el-form-item prop="email">
                  <el-input v-model="form.email" :disabled="companyIsFound">
                  </el-input>
                </el-form-item>
              </div>
              <div class="field">
                <span class="label">Teléfono</span>
                <el-form-item prop="telefono">
                  <el-input v-model="form.telefono" :disabled="companyIsFound">
                  </el-input>
                </el-form-item>
              </div>
              <div class="field">
                <span class="label">Dirección</span>
                <el-form-item prop="direccion">
                  <el-input v-model="form.direccion" :disabled="companyIsFound">
                  </el-input>
                </el-form-item>
              </div>
            </div>
            <div class="tramite-remitente" v-if="form.tipo === 'N'">
              Documento
            </div>
            <div class="tramite-remiteForm" v-if="form.tipo === 'N'">
              <div class="field">
                <span class="label">Documento de identidad</span>
                <el-form-item prop="dni" :error="errors.documento_identidad">
                  <el-input v-model="form.dni" @input="changeDni">
                    <el-button
                      slot="append"
                      icon="el-icon-search"
                      @click="searchDni"
                      style="
                        background:#409eff;
                        color: white;
                      "
                      >Buscar</el-button
                    >
                  </el-input>
                </el-form-item>
              </div>
              <div class="field">
                <span class="label">Nombres</span>
                <el-form-item prop="nombres" :error="errors.nombres">
                  <el-input v-model="form.nombres" :disabled="personIsFound">
                  </el-input>
                </el-form-item>
              </div>

              <div class="field">
                <span class="label">Apellidos</span>
                <el-form-item prop="apellidos">
                  <el-input v-model="form.apellidos" :disabled="personIsFound">
                  </el-input>
                </el-form-item>
              </div>
              <div class="field">
                <span class="label">Email</span>
                <el-form-item prop="email">
                  <el-input v-model="form.email" :disabled="personIsFound">
                  </el-input>
                </el-form-item>
              </div>
              <div class="field">
                <span class="label">Teléfono</span>
                <el-form-item prop="telefono">
                  <el-input v-model="form.telefono" :disabled="personIsFound">
                  </el-input>
                </el-form-item>
              </div>
              <div class="field">
                <span class="label">Dirección</span>
                <el-form-item prop="direccion">
                  <el-input v-model="form.direccion" :disabled="personIsFound">
                  </el-input>
                </el-form-item>
              </div>
            </div>
          </div>
          <div class="tramite-remitente">Documento</div>
          <div class="tramite-remiteForm">
            <div class="field">
              <span class="label">Tipo de documento</span>
              <el-form-item prop="tipo_doc_id">
                <el-select
                  v-model="form.tipo_doc_id"
                  placeholder="Seleccionar documento"
                >
                  <el-option
                    v-for="item in typeDocuments"
                    :key="item.id"
                    :label="item.nombre"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="field">
              <span class="label">Nº de documento</span>
              <el-form-item prop="numero" :error="errors.numero">
                <el-input v-model="form.numero"> </el-input>
              </el-form-item>
            </div>

            <div class="field">
              <span class="label">Nº de folios</span>
              <el-form-item prop="folios" :error="errors.folios">
                <el-input v-model="form.folios" type="number" min="0">
                </el-input>
              </el-form-item>
            </div>
            <div class="field">
              <span class="label">Asunto</span>
              <el-form-item prop="asunto" :error="errors.asunto">
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 4, maxRows: 6 }"
                  v-model="form.asunto"
                  show-word-limit="true"
                  :maxlength="250"
                >
                </el-input>
              </el-form-item>
            </div>
            <div class="field">
              <span class="label">Notas o referencias</span>
              <el-form-item prop="observaciones" :error="errors.observaciones">
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 4, maxRows: 6 }"
                  v-model="form.observaciones"
                  :show-word-limit="true"
                  :maxlength="250"
                >
                </el-input>
              </el-form-item>
            </div>
            <div class="field">
              <span class="label"
                >Documento escaneado o firmado digitalmente</span
              >
              <div class="el-form-item">
                <label for="upload" class="upload_button">
                  <i class="el-icon-upload"></i>
                  Selecciona archivo
                </label>
                <input
                  type="file"
                  name="file"
                  id="upload"
                  accept="application/pdf"
                  @change="uploadFile"
                  ref="fileupload"
                  :class="!form.archivo_id ? 'file_error' : ''"
                  hidden
                />
                <p>{{ this.file.name }}</p>
                <p style="padding-botton:0px" class="file__message">
                  El archivo es obligatorio
                </p>
                <progress max="100" :value.prop="uploadPercentage"></progress>
              </div>
            </div>
            <div class="btn">
              <el-button
                type="primary"
                @click="createExtern('form')"
                :loading="isLoading"
                :disabled="!form.archivo_id"
                icon="el-icon-s-promotion"
                >Enviar</el-button
              >
            </div>
          </div>
        </el-form>
      </div>
    </v-container>
    <NotAvailable v-else />
  </v-container>
</template>
<script>
import axios from "../config/axios";

export default {
  components: {
    NotAvailable: () => import("@/components/NotAvailable"),
  },

  data: () => ({
    uploadPercentage: 0,
    typeProcedures: [],
    typeDocuments: [],
    isLoading: true,
    personIsFound: true,
    companyIsFound: true,
    tipos: [
      { id: "J", name: "PERSONA JURIDICA" },
      { id: "N", name: "CIUDADANO" },
    ],
    errors: {
      nombres: "",
      documento_identidad: "",
      tipo: "",
      dni: "",
      ruc: "",
      razon_social: "",
      email: "",
      direccion: "",
      telefono: "",
      tipo_doc_id: "",
      apellidos: "",
      numero: "",
      folios: "",
      asunto: "",
      observaciones: "",
      archivo_id: "",
      recurrente_id: "",
    },
    errores: {},
    file: "",
    isAvailable: false,
    form: {
      tipo: "N",
      dni: "",
      ruc: "",
      razon_social: "",
      email: "",
      direccion: "",
      telefono: "",
      tipo_doc_id: "",
      nombres: "",
      apellidos: "",
      numero: "",
      folios: 0,
      asunto: "",
      observaciones: "",
      archivo_id: "",
      recurrente_id: "",
    },
    rules: {
      tipo: [
        { required: true, message: "Seleccionar el tipo.", trigger: "blur" },
      ],
      dni: [
        {
          required: true,
          message: "Su DNI es obligatorio",
          trigger: ["blur", "change"],
        },
        {
          pattern: /^[0-9]{8}$/,
          message: "El DNI está conformado por 8 números",
          trigger: "change",
        },
      ],
      ruc: [
        { required: true, message: "Su ruc es obligatorio", trigger: "change" },
        {
          pattern: /^[0-9]{11}$/,
          message: "El RUC está conformado por 11 números",
          trigger: "change",
        },
      ],
      razon_social: [
        {
          required: true,
          message: "La razón social es obligarotia.",
          trigger: ["blur", "change"],
        },
      ],
      email: [],
      direccion: [
        {
          required: true,
          message: "Su dirección es obligatoria.",
          trigger: "change",
        },
      ],
      nombres: [
        {
          required: true,
          message: "Sus nombres son obligatorios",
          trigger: ["blur", "change"],
        },
        {
          message: "Sus nombres no deben contener números",
          pattern: /^[A-Za-z\s]+$/,
          trigger: "change",
        },
      ],
      apellidos: [
        {
          required: true,
          message: "Sus apellidos son obligatorios.",
          trigger: ["blur", "change"],
        },
        {
          message: "Sus apellidos no deben contener números",
          pattern: /^[A-Za-z\s]+$/,
          trigger: "change",
        },
      ],
      numero: [
        {
          required: true,
          message: "El número de documento es obligatorio",
          trigger: "change",
        },
      ],
      asunto: [
        {
          required: true,
          message: "El asunto del trámite es obligatorio.",
          trigger: "change",
        },
      ],
      telefono: [],
      folios: [
        {
          required: true,
          message: "El número de folios es obligatorio",
          trigger: "change",
        },
      ],
      tipo_doc_id: [
        {
          required: true,
          message: "El tipo de documento es obligatorio",
          trigger: "change",
        },
      ],
    },
  }),

  created() {
    this.verifyDisponibility();
    this.getTypeDocuments();
    this.getTypeProcedures();
  },

  methods: {
    changeDni() {
      this.personIsFound = true;
      this.form.nombres = "";
      this.form.apellidos = "";
      this.form.direccion = "";
      this.form.email = "";
      this.form.telefono = "";
      this.form.recurrente_id = "";
    },

    changeRuc() {
      this.companyIsFound = true;
      this.form.email = "";
      this.form.razon_social = "";
      this.form.telefono = "";
      this.form.recurrente_id = "";
      this.form.direccion = "";
    },

    async verifyDisponibility() {
      try {
        const response = await axios.get("/hora_server");
        const disponible = response.data.data.disponible;
        this.isLoading = false;
        this.isAvailable = disponible;
      } catch (error) {
        this.isLoading = false;
        this.isAvailable = true;
      }
    },

    async searchRuc() {
      try {
        const data = {
          tipo_documento_id: 3,
          documento_identidad: this.form.ruc,
        };
        const res = await axios.post(`/personaxdocumento`, data);

        const { email, telefono, id, domicilio, razon_social } = res.data.data;

        this.form.email = email;
        this.form.razon_social = razon_social;
        this.form.telefono = telefono;
        this.form.recurrente_id = id || "";
        this.form.direccion = domicilio;

        this.companyIsFound = true;
      } catch (error) {
        this.companyIsFound = false;

        const errors = error.response.data.error;

        for (let index in errors) {
          let error = errors[index][0];

          this.errors[index] = error;
        }
      }
    },

    async uploadFile() {
      this.form.archivo_id = "";
      this.file = event.target.files[0];
      const formData = new FormData();
      formData.append("file", this.file);

      try {
        const res = await axios({
          url: `/temporals`,
          method: "post",
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: function(progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        });
        this.form.archivo_id = res.data.data.id;
      } catch (error) {
        console.log(error);
        this.form.archivo_id = "";
      }
    },

    querySearch(queryString, cb) {
      const types = this.typeProcedures;
      var results = queryString
        ? types.filter(this.createFilter(queryString))
        : types;
      cb(results);
    },

    createFilter(queryString) {
      return (link) => {
        return (
          link.nombre.toLowerCase().indexOf(queryString.toLowerCase()) === 0
        );
      };
    },

    async searchDni() {
      try {
        const data = {
          tipo_documento_id: 1,
          documento_identidad: this.form.dni,
        };
        const res = await axios.post(`/personaxdocumento`, data);

        const {
          nombres,
          apellidos,
          domicilio,
          email,
          telefono,
          id,
        } = res.data.data;

        this.form.nombres = nombres;
        this.form.apellidos = apellidos;
        this.form.direccion = domicilio;
        this.form.email = email;
        this.form.telefono = telefono;
        this.form.recurrente_id = id || "";

        this.personIsFound = true;
      } catch (error) {
        this.personIsFound = false;
        const errors = error.response.data.error;

        for (let index in errors) {
          let error = errors[index][0];

          this.errors[index] = error;
        }
      }
    },

    async createExtern(formName) {
      if (!this.form.archivo_id) {
        this.$swal("", "El archivo es obligatorio", "error");
      }

      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          try {
            this.isLoading = true;

            const data = {
              ...this.form,
              documento_identidad:
                this.form.tipo == "N" ? this.form.dni : this.form.ruc,
              tipo_documento_id: this.form.tipo == "N" ? 1 : 3,
            };

            const response = await axios.post(`/nuevotramiteexterno`, data);

            this.$swal({
              imageUrl: "",
              html: `<div class="alert">
                <p class="alert__text" style="margin-bottom:15px;font-size:.8em">Su expediente ha sido registrado correctamente.</p>
                <p class="alert__text" style="font-size:.8em" >El Código Único de Documento (CUD) de su trámite es: </p>
                <div class="alert__cud" style="padding:1em;font-size:1.3em" ><b>${response.data.data.cud}</b></div>
                <p class="alert__text" style="margin-bottom:15px;font-size:.8em">Puede hacer el seguimiento respectivo de su trámite </p>
                <p class="alert__text" style="margin-bottom:15px;font-size:.8em">ingresando dicho código en:</p>
                <a class="alert__link" style="font-size:.8em" href="http://consultatramite.munidepocollay.gob.pe">http://consultatramite.munidepocollay.gob.pe</a>
              </div>`,
              showCloseButton: true,
              showCancelButton: false,
              focusConfirm: true,
              confirmButtonText: "OK",
            });

            this.clearForm();
            this.isLoading = false;
          } catch (error) {
            this.$refs.fileupload.value = null;
            this.form.archivo_id = "";

            const errors = error.response.data.error;

            for (let index in errors) {
              let error = errors[index][0];

              this.errors[index] = error;
            }

            this.$swal("Revise sus datos", "", "error");
            this.isLoading = false;
          }
        } else {
          return false;
        }
      });
    },

    async getTypeDocuments() {
      try {
        const response = await axios.get(`/tipo_docs`);
        this.typeDocuments = response.data.data;
      } catch (error) {
        console.log(error);
      }
    },

    async getTypeProcedures() {
      try {
        const response = await axios.get(`/tipo_tramites`);
        this.typeProcedures = response.data.data;
      } catch (error) {
        console.log(error);
      }
    },

    clearForm() {
      this.verifyDisponibility();

      this.file = "";
      this.form = {
        tipo: "N",
        dni: "",
        ruc: "",
        razon_social: "",
        email: "",
        direccion: "",
        telefono: "",
        tipo_doc_id: "",
        nombres: "",
        apellidos: "",
        numero: "",
        folios: 0,
        asunto: "",
        observaciones: "",
        archivo_id: "",
        recurrente_id: "",
      };
      this.errors = {
        nombres: "",
        documento_identidad: "",
        tipo: "",
        dni: "",
        ruc: "",
        razon_social: "",
        email: "",
        direccion: "",
        telefono: "",
        tipo_doc_id: "",
        apellidos: "",
        numero: "",
        folios: "",
        asunto: "",
        observaciones: "",
        archivo_id: "",
      };
      this.$refs.fileupload.value = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.tramite {
  // max-width: 500px;
  width: 100%;
  .tramite-remitente {
    padding: 10px 10px 10px 10px;
    background: rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid black;
    margin-top: 30px;
  }
  .tramite-remiteForm {
    margin-top: 30px;
  }
  .field {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 10px;
    padding-left: 20px;
    .label {
      display: block;
      width: 30%;
      padding: 5px;
    }
  }
}
.el-form-item {
  width: 70%;
}

.el-select {
  width: 100%;
}

.btn {
  display: flex;
  justify-content: center;
}

.upload_button {
  cursor: pointer;
  color: #fff;
  background: rgb(64, 158, 255);
  border-color: #a0cfff;
  display: inline-block;
  padding: 5px 15px;
  border-radius: 4px;
}

.file_error {
  border: 1px solid #f56c6c;
  & + .file__message {
    display: block;
    color: #f56c6c;
    font-size: 12px;
  }
}

.file__message {
  display: none;
}

@media only screen and (max-width: 600px) {
  .toolbar__title {
    display: none;
  }
  .label {
    font-size: 14px;
  }
}

.swal2-title {
  font-family: "Open Sans", sans-serif;
  padding: 2em;
}

.alert__text {
  margin: 1em;
}

.alert__cud {
  padding: 2em;
}

.toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.toolbar__title {
  text-transform: uppercase;
}

.v-progress-circular {
  margin: 0 auto;
  width: 50%;
  display: block;
}

progress::-moz-progress-bar {
  background: #409eff;
}

progress::-webkit-progress-value {
  background: #409eff;
}

progress::-webkit-progress-bar {
  background-color: #eeeeee;
}

progress {
  background: #409eff;
  display: block;
}
</style>
